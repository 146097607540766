<template>
  <div class="layout">
    <Layout :style="{minHeight: '100vh'}">
      <Sider>
        <Menu accordion theme="dark" width="auto">
          <template v-for="(item, index) in menu">
            <template v-if="item.children && item.children.length>0">
              <Submenu :name="index" :key="item.permission_id">
                <template slot="title">
                  <Icon type="ios-menu" />
                  {{item.permission_name}}
                </template>
                <MenuItem v-for="(value, key) in item.children" :key="value.permission_id" :name="index+'-'+key" :to="value.permission_mark">{{value.permission_name}}</MenuItem>
              </Submenu>
            </template>
            <template v-else>
              <MenuItem :name="index" :key="item.permission_id" :to="item.permission_mark">
                <span>{{item.permission_name}}</span>
              </MenuItem>
            </template>
          </template>
        </Menu>
      </Sider>
      <Layout>
        <Header style="background-color: #fff;box-shadow: 0 2px 3px 2px rgba(0,0,0,.1);padding: 0;display: flex">
          <div style="display: flex;align-items: center;height: 100%;width: 3%;justify-content: center" @click="back()">
            <Icon type="ios-arrow-dropleft" style="font-size: 40px;color: #515a6e" v-if="lastPageUrl !== ''"/>
          </div>
          <div style="flex: 1;text-align: right;padding-right: 20px">
            <Dropdown @on-click="logout">
              <a href="javascript:void(0)">
                {{nick}}
                <Icon type="ios-arrow-down"></Icon>
              </a>
              <DropdownMenu slot="list">
                <DropdownItem style="text-align: center" >退出</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Header>
        <Content style="padding: 10px 0">
          <Card style="min-height: 92vh">
            <router-view @lastPage="lastPage"/>
          </Card>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import api from '../api/setup';

export default {
  name: "Main",
  data: function(){
    return {
      menu: [],
      lastPageUrl: '',
      nick: ''
    }
  },
  computed: {
    menuitemClasses: function () {
      return [
        'menu-item',
        this.isCollapsed ? 'collapsed-menu' : ''
      ]
    }
  },

  methods: {
    logout: function(){
      localStorage.removeItem('token');
      localStorage.removeItem('nick');
      window.location.reload();
    },
    back: function(){
      this.$router.replace(this.lastPageUrl)
    },
    lastPage: function(url){
      this.lastPageUrl = url;
    }
  },
  mounted() {
    this.nick = localStorage.getItem('nick');
    const token = localStorage.getItem('token');
    if(token === undefined || token === null || token.toLowerCase()==="null" || token === "" || token.replace(/(^s*)|(s*$)/g, "").length ===0){
      this.$router.replace('/login')
    } else {
      this.$http.get(api.getAllowMenusOrButton, {params:{type:'menu'}}).then(response => {
        if(response.data.success) {
          this.menu = response.data.res;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      });
    }
  },
  destroyed() {
  }
}
</script>

<style scoped>
.layout-con{
  height: 100%;
  width: 100%;
}
.menu-item span{
  display: inline-block;
  overflow: hidden;
  width: 69px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  transition: width .2s ease .2s;
}
.menu-item i{
  transform: translateX(0px);
  transition: font-size .2s ease, transform .2s ease;
  vertical-align: middle;
  font-size: 16px;
}
</style>