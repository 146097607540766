import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is_loading: false,
    buttons: [],
    socket: {
      socketTimestamp: new Date().getTime(),
      isConnected: false,
      message: '',
      reconnectError: false,
    }
  },
  mutations: {
    loading: function(state, payload){
      state.is_loading = payload
    },
    setButtons: function(state, payload){
      state.buttons = payload
    },
    SOCKET_ONOPEN (state, event)  {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, event)  {
      console.log(event)
      state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event)  {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message)  {
      state.socket.message = message;
      state.socket.socketTimestamp = new Date().getTime();
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
      console.log('正在重新连接websocket');
      Vue.prototype.$connect();
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
  },
  actions: {
    loading: function (context, payload) {
      context.commit('loading', payload)
    },
    setButtons: function(context, payload){
      context.commit('setButtons', payload)
    },
    sendMessage: function(context, message) {
      Vue.prototype.$socket.sendObj(message)
    }
  },
  modules: {
  }
})
