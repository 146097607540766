import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Message } from 'view-design';
import './directive/allow';
// import VueNativeSock from 'vue-native-websocket'

Vue.use(ViewUI);
Vue.use(VueAxios, axios)
// Vue.use(VueNativeSock, 'wss://gateway.tsyungu.com:2120', {
//     connectManually: true,
//     format: 'json',
//     store: store,
//     reconnection: true, // (Boolean) whether to reconnect automatically (false)
//     reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
//     reconnectionDelay: 3000,
// })
Vue.config.productionTip = false

if (process.env.NODE_ENV === "development") {
    // axios.defaults.baseURL = 'https://xcxhd.wangyuerencai.com';
    axios.defaults.baseURL = 'https://xcxhd.wangyuerencai.com';
} else {
    axios.defaults.baseURL = 'https://xcxhd.wangyuerencai.com';
}
axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
    store.dispatch('loading', true);
    config.headers.mytoken = localStorage.getItem('token')
    return config;
});

axios.interceptors.response.use(
    response => {
        store.dispatch('loading', false);
        return response
    },
    error => {
        store.dispatch('loading', false);
        switch (error.response.status) {
            case 401:
                // alert('401');
                router.replace('/login')
                break;
            case 403:
                Message.error('没有权限');
                break;
        }
    }
);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
