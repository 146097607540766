<template>
  <div id="app">
    <router-view/>
    <Spin size="large" fix v-if="isLoading" style="z-index: 99999999">请稍候...</Spin>
  </div>
</template>
<script>
export default {
  name: "App",
  data: () => ({}),
  computed: {
    isLoading: function(){
      return this.$store.state.is_loading;
    },
  },
  mounted() {
    this.$Message.config({
      duration: 10
    });
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
