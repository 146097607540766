import Main from '../views/Main';

const setupRouter = {
    path: '/setup',
    component: Main,
    children: [
        {
            path: 'users',
            name: 'Users',
            component: () => import('../views/setup/Users'),
        },
        {
            path: 'user',
            name: 'User',
            component: () => import('../views/setup/User')
        },
        {
            path: 'user/add',
            name: 'AddUser',
            component: () => import('../views/setup/AddUser')
        },
        {
            path: 'permissions',
            name: 'Permissions',
            component: () => import('../views/setup/Permissions')
        },
        {
            path: 'roles',
            name: 'Roles',
            component: () => import('../views/setup/Roles')
        },
        {
            path: 'permission/add',
            name: 'AddPermission',
            component: () => import('../views/setup/AddPermission')
        },
        {
            path: 'permission/mod',
            name: 'ModPermission',
            component: () => import('../views/setup/ModPermission')
        },
        {
            path: 'roles/add',
            name: 'AddRole',
            component: () => import('../views/setup/AddRole')
        },
        {
            path: 'roles/mod',
            name: 'Role',
            component: () => import('../views/setup/role')
        },
        {
            path: 'swiper',
            name: 'Swiper',
            component: () => import('../views/setup/Swiper')
        },
    ]
}
export default setupRouter