import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main';
import setupRouter from "./setup";
import store from '../store';
import api from '../api/index';
import { Message } from 'view-design';

import xcxRouter from './xcx';


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      beforeEachExcept: true
    },
  },
  {
    path: '/gzhpaper',
    name: 'Gzhpaper',
    component: () => import('../views/Gzhpaper'),
    meta: {
      beforeEachExcept: true
    },
  },
  {
    path: '/gzhpaperadd',
    name: 'Gzhpaperadd',
    component: () => import('../views/Gzhpaperadd'),
    meta: {
      beforeEachExcept: true
    },
  },
  {
    path: '/gzhpapermod',
    name: 'Gzhpapermod',
    component: () => import('../views/Gzhpapermod'),
    meta: {
      beforeEachExcept: true
    },
  },
  {
    path: '/gzhpapersubmit',
    name: 'Gzhpapersubmit',
    component: () => import('../views/Gzhpapersubmit'),
    meta: {
      beforeEachExcept: true
    },
  },
  {
    path: '/gzhpapersubmitdetail',
    name: 'Gzhpapersubmitdetail',
    component: () => import('../views/Gzhpapersubmitdetail')
  },

  {
    path: '/',
    component: Main,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('../views/dashboard/Dashboard'),
        name: 'Dashboard',
      }
    ]
  },
  setupRouter,

  xcxRouter,

  {
    path: '*',
    component: () =>import('../views/NotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.beforeEachExcept !== true) {
    Vue.prototype.$http.get(api.getButtons, {params: {type:'button', mark: to.path}}).then(response => {
      if(response.data.success) {
        store.dispatch('setButtons', response.data.res)
      } else {
        Message.error(response.data.err);
        console.log(response)
      }
    }).finally(() => {
      next()
    })
  } else {
    next();
  }
})

export default router
