import Vue from 'vue';
// import store from '../store';

Vue.directive('allow', {
    inserted: function(el, binding, vnode){
        let mark = binding.value;
        for(let item of vnode.context.$store.state.buttons) {
            // console.log(vnode.context.$store.state.buttons)
            if(item.permission_mark === mark){
                el.disabled = false
                return;
            }
        }
        el.title = '没有权限';
        el.disabled = true;
    }
})