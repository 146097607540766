import Main from '../views/Main';

const xcxRouter = {
    path: '/xiaochengxu',
    component: Main,
    children: [
        {
            path: 'activity',
            name: 'Activity',
            component: () => import('../views/xcx/Activity')
        },
        {
            path: 'activity/detail',
            name: 'ActivityDetail',
            component: () => import('../views/xcx/ActivityDetail')
        },
        {
            path: 'paper',
            name: 'Paper',
            component: () => import('../views/xcx/Paper')
        },
        {
            path: 'paper/add',
            name: 'AddPaper',
            component: () => import('../views/xcx/AddPaper')
        },
        {
            path: 'paper/submit',
            name: 'PaperSubmit',
            component: () => import('../views/xcx/PaperSubmit')
        },
        {
            path: 'paper/submitdetail',
            name: 'PaperSubmitDetail',
            component: () => import('../views/xcx/PaperSubmitDetail')
        },
        {
            path: 'paper/detail',
            name: 'PaperDetail',
            component: () => import('../views/xcx/PaperDetail')
        },
        {
            path: 'user',
            name: 'User',
            component: () => import('../views/xcx/User')
        },
        {
            path: 'user/detail',
            name: 'UserDetail',
            component: () => import('../views/xcx/UserDetail')
        },
        {
            path: 'quan',
            name: 'Quan',
            component: () => import('../views/xcx/Quan')
        },
        {
            path: 'quan/detail',
            name: 'quan/detail',
            component: () => import('../views/xcx/QuanDetail')
        },
        {
            path: 'tag',
            name: 'Tag',
            component: () => import('../views/xcx/Tag')
        },
        {
            path: 'activity/apply',
            name: 'ActivityApply',
            component: () => import('../views/xcx/ActivityApply')
        },
        {
            path: 'userlog',
            name: 'UserLog',
            component: () => import('../views/xcx/UserLog')
        },
        {
            path: 'news',
            name: 'News',
            component: () => import('../views/xcx/News')
        },
        {
            path: 'news/add',
            name: 'NewsAdd',
            component: () => import('../views/xcx/NewsAdd')
        },
        {
            path: 'news/update',
            name: 'NewsUpdate',
            component: () => import('../views/xcx/NewsUpdate')
        }
    ]
}
export default xcxRouter