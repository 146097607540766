const api = {
    // 'baseUrl': 'http://juzhi/',
    'baseUrl': 'https://xcxhd.wangyuerencai.com/',
    'userList' : 'setup/user',
    'userDetail': 'setup/user',
    'getRoles': 'setup/role',
    'getRole': 'setup/role',
    'modRole': 'setup/role',
    'permissions': 'setup/permission',
    'addPermission': 'setup/permission',
    'permissionChangeOrder': 'setup/permission',
    'delPermission': 'setup/permission',
    'permission': 'setup/permission',
    'modPermission': 'setup/permission',
    'addRole': 'setup/role',
    'delRole': 'setup/role',
    'getPermissionsForRole': 'setup/permission/role',
    'setApiPermission': 'setup/role/permission',
    'setMenuPermission': 'setup/role/permission',
    'getAllowMenusOrButton': 'setup/permission/user',

    'modUser': 'setup/user',
    'gatewayBindUser': 'setup/user/gateway',

    'addUser': 'setup/user',

    'uploadSwiper': 'setup/swiper/upload',
    'swiper': 'setup/swiper',
    'delSwiper': 'setup/swiper',

    'articleList': 'xiaochengxu/article',

};
export default api;